import { FaTag } from "react-icons/fa/";
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import List from "../components/List";
import Seo from "../components/Seo";

const CategoryPage = props => {
  const {
    data: {
      posts: { edges: posts = [] },
      site: {
        siteMetadata: { facebook }
      }
    }
  } = props;

  // Create category list
  const categories = {};

  posts.forEach(({ node }) => {
    const tags = node.tags;
    tags.forEach(tag => {
      if (tag && tag != null) {
        if (!categories[tag]) {
          categories[tag] = [];
        }
        categories[tag].push({ node: node });
      }
    });
  });

  const categoryList = [];

  for (var key in categories) {
    categoryList.push([key, categories[key]]);
  }

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline title="Posts by categories" theme={theme} />
            </header>
            {categoryList.map(item => (
              <section key={item[0]}>
                <h2>
                  <FaTag /> {item[0]}
                </h2>
                <List edges={item[1]} theme={theme} />
              </section>
            ))}
            {/* --- STYLES --- */}
            <style jsx>{`
              h2 {
                margin: 0 0 0.5em;
              }
              h2 :global(svg) {
                height: 0.8em;
                fill: ${theme.color.brand.primary};
              }
            `}</style>
          </Article>
        )}
      </ThemeContext.Consumer>

      <Seo facebook={facebook} />
    </React.Fragment>
  );
};

CategoryPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default CategoryPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query getAllPosts {
    posts: allPrismicGenericPost(sort: { fields: first_publication_date, order: DESC }) {
      edges {
        node {
          id
          first_publication_date
          last_publication_date
          slugs
          uid
          tags
          data {
            author_link {
              id
              slug
              document {
                data {
                  author_name {
                    html
                    text
                  }
                }
              }
            }
            title {
              html
              text
            }
            content {
              html
              text
              raw {
                type
                text
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
